<template>
  <div class="page-notifications page-content">
    <KlubbaRichText class="texts">
      <p><b>{{ progress.title }}</b></p>
      <p>{{ progress.description }}</p>
    </KlubbaRichText>

    <div class="container">
      <div class="card-wrap">
        <klubba-checkbox
          v-for="(item, itemKey) in notifications"
          :key="itemKey"
          v-model="item.selected"
          :label="item.label"
          class="klubba-checkbox"
          :class="{ 'is-loading': loading }"
        />

        <KlubbaLoaderIcon :active="loading" />
      </div>
    </div>

    <div class="button-wrap">
      <klubba-footer
        backButton="Back"
        @goPrevPage="prevPage"
        @goNextPage="nextPage"
        :loading="loading"
      />
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert';
import { mapGetters } from 'vuex';
import KlubbaFooter from '@/components/layout/KlubbaFooter.vue';
import KlubbaCheckbox from '@/components/default/KlubbaCheckbox.vue';
import KlubbaRichText from '../../components/default/KlubbaRichText.vue';
import firebaseApp from '../../config/firebase';
import KlubbaLoaderIcon from '../../components/default/KlubbaLoaderIcon.vue';
import setOrUpdateRef from '../../tools/setOrUpdateRef';

export default {
  components: {
    KlubbaLoaderIcon,
    KlubbaRichText,
    KlubbaFooter,
    KlubbaCheckbox,
  },
  data() {
    return {
      progress: {
        title: 'How would you like to be notified',
        description: 'Please note Klubba will never sell your information to a third party.',
      },
      notifications: [
        {
          label: 'Send me SMS messages for critical messages',
          value: 'sms',
          selected: true,
        },
        {
          label: 'Send me Push Notifications connected to my phone wallet pass for important messages',
          value: 'push',
          selected: true,
        },
        {
          label: 'Allow vendors I have a membership with to send me marketing communications',
          value: 'marketing',
          selected: false,
        },
      ],
      loading: false,
    };
  },
  mounted() {
    if (this.savedNotifications) {
      this.savedNotifications.forEach((value) => {
        this.notifications.find((notification) => notification.value === value).selected = true;
      });

      console.log(this.notifications);
    }
  },
  computed: {
    ...mapGetters(['club', 'firebaseUser', 'clubSpecific', 'user']),
    savedNotifications() {
      return this.$store.getters.clubSpecificByKey('notifications');
    },
  },
  methods: {
    prevPage() {
      this.$router.back();
    },
    async nextPage() {
      // Save info
      this.$store.commit('setNewUserClubSpecific', { notifications: this.notifications.filter((notification) => notification.selected).map((i) => i.value) });

      if (this.user) {
        // Update club specific if user already exists
        this.loading = true;
        const db = firebaseApp.firestore();

        const clubSpecificRef = db.collection('users').doc(this.firebaseUser.uid).collection('club_specific').doc(this.club.id);
        const clubSpecificData = this.$store.getters.newUserClubSpecific;

        await setOrUpdateRef(clubSpecificRef, clubSpecificData)
          .then(() => {
            this.$router.push(`/${this.club.id}/add-wallet`);
          })
          .catch((error) => {
            swal('Error', error.message, 'error');
          });

        this.loading = false;
      } else {
        // If user doesn't exist, docs will be created on card-review page
        this.$router.push(`/${this.club.id}/card-review`);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.texts
  @apply p-8

.container
  @apply px-8

.card-wrap
  @apply bg-white rounded-radius-10 p-5 relative

.button-wrap
  @apply p-8 mt-auto

.klubba-checkbox
  transition: opacity var(--transition)
  &.is-loading
    opacity: .3
</style>
